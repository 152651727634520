import { Component } from 'react';
import Axios from 'axios';
import validator from 'validator'
class IdentifyForm extends Component{
    constructor(props){
        super(props)
        this.state = {
            email: '',
            msg: '',
            url: 'www.someurl.com',
            token: '123',
            title: 'some title',
            validEmail: false,
            acceptMarketingMaterial: true,
        }
    }
    validateEmail = (e) => {
        var email = e.target.value
        this.setState({
            email: e.target.value
        })
        
        if (validator.isEmail(email)) {
            this.setState({
                validEmail: true,
            })
        } else {
            this.setState({
                validEmail: false,
            })
        }
    }
    handlerEmailChange = event => {
        this.setState({
            email: event.target.value
        })
    }
    handlerURLChange = event => {
        this.setState({
            url: event.target.value
        })
    }
    handlerTitleChange = event => {
        this.setState({
            title: event.target.value
        })
    }
    handlerTokenChange = event => {
        this.setState({
            token: event.target.value
        })
    }
    submitForm = async (event) => {
        event.preventDefault();
        console.log(this.state.email);
        await Axios.post('/identify', {
            email: this.state.email,
            acceptMarketingMaterial: this.state.acceptMarketingMaterial 
        }).then( (res) => {
            let data = res.data;
            if (data.redirect){
                window.location.href = decodeURIComponent(this.props.next);
            }
            if(data.msg){
                this.setState({
                    msg: data.msg,
                });
            }
        });
    }

    submitFormGetID  = async( event) => {
        event.preventDefault();
        await Axios.get('/getSavedProjects').then( (res) => {
            let data = res.data;
            console.log(data);
            if (data.redirect){
                window.location.href = data.redirect;
            }
            if(data.msg){
                this.setState({
                    msg: data.msg + data.savedProjects,
                });
            }
        });
    }

    submitFormTestD  = async( event) => {
        event.preventDefault();
        await Axios.get('/test').then( (res) => {
            let data = res.data;
            console.log(data);
            if (data.redirect){
                window.location.href = data.redirect;
            }
            if(data.msg){
                this.setState({
                    msg: data.msg + data.saveProjects,
                });
            }
        });
    }

    submitFormAddURL  = async( event) => {
        event.preventDefault();
        await Axios.post('/saveProject2', {
            token: this.state.token,
            url: this.state.url,
            title: this.state.title,
        }).then( (res) => {
            let data = res.data;
            if (data.redirect){
                window.location.href = data.redirect;
            }
            if(data.msg){
                this.setState({
                    msg: data.msg,
                });
            }
        });
    }
    submitFormGetCartID  = async( event) => {
        event.preventDefault();
        await Axios.get('/cart-id').then( (res) => {
            let data = res.data;
            console.log(data);
            if(data){
                this.setState({
                    msg: data,
                });
            }
        });
    }
    render() {
        return (
            <div className="identifyDiv">
                <form onSubmit={this.submitForm}>
                    <div>
                        <label className="identifyEmailLabel">Enter your email to continue</label>
                        <br></br><br></br>
                    </div>
                    <div>
                        <span className="identifyBody">We’ll create an account for you, or save your order to an existing account if you have one.</span>
                        <br></br><br></br>
                    </div>
                    <br></br>
                    <div className="indentifyInputDiv">
                        <input className="identifyInput" type="text" placeholder="Email Address" value={this.state.email} onChange={this.validateEmail}></input>
                        <button className="identifyButton" type="submit" disabled={!this.state.validEmail}>Continue</button>
                    </div>
                    {/* <div className="identifyCheckbox">
                        <input className="identifyCheckboxInput" type="checkbox" defaultChecked={this.state.acceptMarketingMaterial} onChange={(e) => this.setState({acceptMarketingMaterial: e.target.value })} ></input>
                        <label className="identifyCheckboxLabel">Sign up for emails + take 15% off your first order.</label>
                    </div> */}
                    {/* <label className="errorLabel" hidden={this.state.validEmail}>Please provide a valid email.</label> */}
                    
                </form>
                <label>{this.state.msg}</label>

                {/* <form onSubmit={this.submitFormGetID}>
                    <button type="submit">Get ID</button>
                </form> 

                <form onSubmit={this.submitFormAddURL}>
                    <div>
                        <label>Token</label>
                        <input type="text" value={this.state.token} onChange={this.handlerTokenChange}></input>
                        <label>Product Title</label>
                        <input type="text" value={this.state.title} onChange={this.handlerTitleChange}></input>
                        <label>URL</label>
                        <input type="text" value={this.state.url} onChange={this.handlerURLChange}></input>
                    </div>
                    <button type="submit">Save Fake project</button>
                </form>

                <form onSubmit={this.submitFormTestD}>
                    <button type="submit">Test (alex usage)</button>
                </form>
                <div>
                    <form onSubmit={this.submitFormGetCartID}>
                        <button type="submit">Get cartID</button>
                    </form>
                    
                </div>
                */}
            </div>
        )
    }
}

export default IdentifyForm